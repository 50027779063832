.form-company-info {
  .form-company-info {
    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 16px;
    }
  }
}
.checkbox-wrapper {
  align-self: flex-start;
}