.b-profession-select {
  &-trigger {
    text-align: left;
    background-color: transparent;
    margin: 0;
    padding: 0;
    width: 100%;
    border: none;
    outline: none;
  }
}
